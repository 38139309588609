import { Popover } from "antd";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import React from "react";
const Delete = React.lazy(()=>import('./delete'));
const Index = () => {

  return (
    <div className="App">
      <div className="left">
        <div className="content">

        <div className="title"> MEBOO </div>

        <div className="secTitle">
        Keep up with the trend and groove with your buddies!
        </div>

        <a className="appstore" href="https://apps.apple.com/cn/app/id6471480825" target="_blank" rel="noreferrer">
          <img src={require('./assets/appstore.png')} className="img" alt=""/>
        </a>

        <div className="chose">
          <div className="option">
            <a
              href="./terms.html"
              target="_blank"
              className="option_item"
            >
              <img src={require('./assets/term.webp')} alt=""/>
              Terms
            </a>
            <Popover content={"contact@meboo-app.net"}>
              <div className="option_item">
                <img src={require('./assets/Help.webp')} alt=""/>
                Help
              </div>
            </Popover>
            <a
              href="./PrivacyPolicy.html"
              target="_blank"
              className="option_item"
            >
              <img src={require('./assets/eye.webp')} alt=""/>
              PrivacyPolicy
            </a>
          </div>
        </div>

        <div className="download">
          <p>@2023 www.meboo-app.net</p>
          <p>Company:YOHO IT SDN. BHD</p>
        </div>

        <div className="address">
        Address: 15-2 Dinasti Sentral Jalan Kuchai Maju 18,Off Jalan Kuchai Lama,KUALA LUMPUR,Wilayah Persekutuan,58200
        </div>
      </div>

      </div>
      <div className="right">
        <img src={require('./assets/logo.webp')} alt=""/>
      </div>
    </div>
  );
};
const App = () => {
  
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/deleteAccount.html" element={<Delete />} />
    </Routes>
  );
};

export default App;
